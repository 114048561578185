<script setup lang="ts">
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { dir } from '@@/bits/current_dir'
import device from '@@/bits/device'
import { __ } from '@@/bits/intl'
import { isPostEmpty } from '@@/bits/post_properties'
import { defineAsyncComponent } from '@@/bits/vue'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import { useComposerModalAlertStore } from '@@/pinia/composer_modal_alert'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { usePostComposerModalStore } from '@@/pinia/post_composer_modal_store'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceContainerSizeStore } from '@@/pinia/surface_container_size'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { DropZone, useSurfaceDragAndDropEventsStore } from '@@/pinia/surface_drag_and_drop_events_store'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import type { Cid, PopoverAnchor } from '@@/types'
import DockableModalList, { ModalState } from '@@/vuecomponents/DockableModalList.vue'
import { useCheckUnsavedChangesBeforeReload } from '@@/vuecomposables/useCheckUnsavedChangesBeforeReload'
import { storeToRefs } from 'pinia'
import type { ComponentPublicInstance } from 'vue'
import { computed, ref, watch } from 'vue'

const SurfaceComposerModal = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceComposerModal.vue'))
const SurfaceComposerModalMinimized = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceComposerModalMinimized.vue'),
)
const SurfaceDraftBulkActionPopover = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceDraftBulkActionPopover.vue'),
)
const SurfaceComposerDraftsPanel = defineAsyncComponent(() => import('@@/vuecomponents/SurfaceComposerDraftsPanel.vue'))
const SurfaceEditAttachmentThumbnailModal = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceEditAttachmentThumbnailModal.vue'),
)

const {
  isPhone,
  isLandscapePhone,
  surfaceContainerWidth,
  surfaceContainerHeight,
  isContainerSmallerThanTabletPortrait,
  isContainerSmallerThanTabletLandscape,
} = storeToRefs(useSurfaceContainerSizeStore())
const { isSubmissionRequest, headerHeight } = storeToRefs(useSurfaceStore())
const { isHandlingDragEvent, activeDropZone } = storeToRefs(useSurfaceDragAndDropEventsStore())

const { openConfirmationDialog } = useGlobalConfirmationDialogStore()
const { openComposerAlert } = useComposerModalAlertStore()

const surfaceDraftsStore = useSurfaceDraftsStore()
const { draftCids, draftByCid, isAnyDraftActive, activeDraft, activeDraftCid, hasFetchedInitialPostDrafts } =
  storeToRefs(surfaceDraftsStore)
const {
  isDraftDirty,
  isDraftAutoSavePending,
  cancelEditingDraft,
  publishAllPostDrafts,
  removeDraft,
  removeAllPostDrafts,
} = surfaceDraftsStore

const surfacePostsStore = useSurfacePostsStore()
const { postBeingEdited, postEntitiesByCid } = storeToRefs(surfacePostsStore)
const { isExistingPost, stopEditingPost } = surfacePostsStore

const draftModals = computed(() => {
  // For Submission Request, a new post is created in onMounted of `SurfaceContainerSubmissionRequest`.
  // That sets the active draft to the new post, which we want to display.
  if (isSubmissionRequest.value) {
    return activeDraftCid.value != null ? [{ id: activeDraftCid.value }] : []
  }

  // For Phone viewport, we don't show a list of drafts. There's already a panel for that.
  // We only show the active draft (which has a button to open the drafts panel).
  // But if the active draft is an existing post, we return an empty array here because it's
  // passed to `DockableModalList` from the `postBeingEditedModal` computed property.
  if (isPhone.value) {
    const isActiveDraftExistingPost = activeDraftCid.value != null && isExistingPost(activeDraftCid.value)
    return activeDraftCid.value == null || isActiveDraftExistingPost ? [] : [{ id: activeDraftCid.value }]
  }

  return draftCids.value
    .filter((cid) => {
      // Exclude draft of an existing post. It is passed to `DockableModalList` in `postBeingEditedModal`.
      return !isExistingPost(cid)
    })
    .map((cid) => draftByCid.value[cid])
    .sort((draftA, draftB) => {
      // Drafts needed to be sorted by `created_at` for them to appear in the correct order
      // in the dock.
      const draftACreatedAt = new Date(draftA.created_at ?? '').getTime()
      const draftBCreatedAt = new Date(draftB.created_at ?? '').getTime()
      return draftACreatedAt - draftBCreatedAt
    })
    .map((draft) => ({
      id: draft.cid,
      // If we want to edit the draft immediately, don't set state. `DockableModalList` will automatically
      // pick an appropriate state. Otherwise, we want to minimize the draft to avoid disrupting what the
      // user is doing.
      state: draft.shouldStartEditing === true ? undefined : ModalState.Minimized,
    }))
})

const postBeingEditedModal = computed(() => (postBeingEdited.value != null ? { id: postBeingEdited.value.cid } : null))

// Auto-close the drafts panel when the surface container gets large enough.
watch(isPhone, (isSurfaceContainerPhone) => {
  if (!isSurfaceContainerPhone) xDraftsPanel.value = false
})

const postComposerModalStore = usePostComposerModalStore()
const {
  hasExpandedComposerModal,
  isSubmissionRequestComposerModalExpanded,
  isShowingAttachmentPreview,
  xEditThumbnailModal,
} = storeToRefs(postComposerModalStore)
const { registerDockableModalListInstance } = postComposerModalStore

const isPhoneVariant = computed<boolean>(() => {
  return isPhone.value || (device.mobile && isLandscapePhone.value)
})

const computedComposerModalListContainerHeight = computed(() => {
  // In Submission Request, we can collapse the composer to show the surface header.
  // We do that by decreasing the container height for `DockableModalList`.
  if (isPhone.value && isSubmissionRequest.value && !isSubmissionRequestComposerModalExpanded.value) {
    return surfaceContainerHeight.value - headerHeight.value
  }
  return surfaceContainerHeight.value
})

const isWideModal = computed(() => {
  return (
    device.mobile &&
    isShowingAttachmentPreview.value &&
    surfaceContainerWidth.value > computedComposerModalListContainerHeight.value
  )
})

const dockableModalList = ref<InstanceType<typeof DockableModalList>>()
registerDockableModalListInstance(dockableModalList)

const cancelEditing = () => {
  if (isSubmissionRequest.value) {
    // Don't do anything. Only option for user is the "Clear" button
    // which clears the post composer content.
    // See SurfaceComposerModalHeaderBar.vue
    return
  }

  // If it's a post being edited, stop it or warn the user if there are
  // unsaved changes.
  if (postBeingEdited.value != null) {
    if (isDraftDirty(postBeingEdited.value.cid)) {
      openComposerAlert({
        title: __('You have unsaved changes'),
        body: __('Are you sure you want to discard your changes and close this post composer?'),
        primaryActionText: __('Discard'),
        secondaryActionText: __('Keep working'),
        primaryActions: [cancelEditingDraft],
        secondaryActions: [],
        isPrimaryActionDanger: true,
      })
    } else {
      stopEditingPost()
    }
    return
  }

  if (activeDraft.value == null) return

  // If the draft is empty, delete it.
  if (isPostEmpty(activeDraft.value)) {
    removeDraft(activeDraft.value.cid)
    return
  }

  // Otherwise, minimize the draft.
  // `stopEditingPost` actually also clears the `activeDraft` state so
  // we use it here. It's used to both stop editing a post and a draft.
  dockableModalList.value?.minimizeModal(activeDraft.value.cid)
  stopEditingPost()
}

const xDraftsPanel = ref(false)
const bulkActionButton = ref<ComponentPublicInstance>()
const xBulkActionMenu = ref(false)
const bulkActionPopoverAnchor = ref<PopoverAnchor | null>(null)

const updateBulkActionPopoverAnchor = () => {
  const bulkActionButtonEl = bulkActionButton.value?.$el as HTMLElement | undefined
  if (bulkActionButtonEl == null) {
    return
  }

  const bulkActionButtonRect = bulkActionButtonEl.getBoundingClientRect()
  bulkActionPopoverAnchor.value = {
    top: bulkActionButtonRect.top,
    left: bulkActionButtonRect.left,
    width: bulkActionButtonRect.width,
    position: 'top',
  }
}

watch([surfaceContainerWidth, surfaceContainerHeight], () => {
  if (xBulkActionMenu.value) updateBulkActionPopoverAnchor()
})

const openBulkActionMenu = () => {
  updateBulkActionPopoverAnchor()
  xBulkActionMenu.value = true
}

const closeBulkActionMenu = () => {
  xBulkActionMenu.value = false
}

const publishAllDrafts = () => {
  closeBulkActionMenu()
  publishAllPostDrafts()
}

const deleteAllDrafts = () => {
  closeBulkActionMenu()
  openConfirmationDialog({
    ...ALERT_ICON,
    isCodeProtected: false,
    title: __('Delete all drafts?'),
    body: __('You can’t undo this action.'),
    confirmButtonText: __('Delete drafts'),
    cancelButtonText: __('Cancel'),
    afterConfirmActions: [removeAllPostDrafts],
    buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
    forceFullWidthButtons: true,
    xShadow: true,
  })
}

useCheckUnsavedChangesBeforeReload({
  shouldAlert: () => {
    return draftCids.value.some((draftCid: Cid) => {
      const draft = draftByCid.value[draftCid]

      if (!draft) {
        return false
      }

      const isUnsavedNewNonEmptyDraft = isDraftAutoSavePending(draftCid)

      const isDraftWithFileUpload = !!draft.uploadingFile

      const isDraftChangedFromOriginalPost: boolean = (() => {
        if (!isExistingPost(draftCid)) {
          return false
        }
        const post = postEntitiesByCid.value[draftCid]
        return (
          draft.subject !== post?.subject ||
          draft.body !== post?.body ||
          draft.attachment !== post?.attachment ||
          draft.attachment_caption !== post?.attachment_caption
        )
      })()

      return isUnsavedNewNonEmptyDraft || isDraftWithFileUpload || isDraftChangedFromOriginalPost
    })
  },
})

const isDropZoneToCreatePost = computed(
  () =>
    (activeDropZone.value === DropZone.DOCUMENT && isAnyDraftActive.value) ||
    activeDropZone.value === DropZone.POST_COMPOSER,
)
</script>

<template>
  <!-- Only render the component after we have fetched post drafts. -->
  <!-- This allows `DockableModalList` to set the `initialModalState` to modals properly. -->
  <DockableModalList
    v-if="hasFetchedInitialPostDrafts"
    ref="dockableModalList"
    :class="[
      'absolute',
      'inset-0',
      'z-dockable-modal',
      isHandlingDragEvent
        ? {
            // We show an overlay background for both DOCUMENT and POST_COMPOSER drop zones
            // because they can both be dropped on to create a post.
            'ease-in bg-grape-500/50 dark:bg-canary-500/[.35]': isDropZoneToCreatePost,
            'ease-out bg-grape-500/0 dark:bg-canary-500/0': !isDropZoneToCreatePost,
          }
        : {
            // Normal overlay background for expanded post composer modal.
            'ease-in bg-modal-overlay-light dark:bg-modal-overlay-dark': hasExpandedComposerModal,
            'ease-out bg-none': !hasExpandedComposerModal,
          },
      // Transition the overlay background for 180ms.
      'duration-180',
    ]"
    :modal-data-array="draftModals"
    :immutable-modal-data="postBeingEditedModal"
    :initial-modal-state="isPhone || isSubmissionRequest ? ModalState.Expanded : ModalState.Minimized"
    :default-new-modal-state="device.mobile || isPhone || isSubmissionRequest ? ModalState.Expanded : ModalState.Docked"
    :is-phone="isPhoneVariant"
    :is-wide-modal="isWideModal"
    :dir="dir()"
    :container-width="surfaceContainerWidth"
    :container-height="computedComposerModalListContainerHeight"
    :bottom-padding="!isPhone && isContainerSmallerThanTabletLandscape ? 52 : 0"
    :end-padding="!isPhone && isContainerSmallerThanTabletLandscape ? 16 : 132"
    @scrim-click="cancelEditing"
    @scrim-esc="cancelEditing"
  >
    <template #bulk-action-content>
      <OzBaseButton
        ref="bulkActionButton"
        :class="[
          'overflow-hidden',
          'outline-none',
          'rounded-t-2xl',
          'shadow-elevation-2',
          'w-full',
          'h-10',
          'bg-light-ui-100 dark:bg-dark-ui-100',
          'ring-grape-500 dark:ring-canary-500',
          'focus-visible:ring-[2.5px]',
          'text-dark-text-200 dark:text-light-text-200',
          'hover:text-dark-text-100 dark:hover:text-light-text-100',
          'active:text-grape-500 dark:active:text-canary-500',
          'focus-visible:text-grape-500 dark:focus-visible:text-canary-500',
        ]"
        :title="__('Open drafts bulk action menu')"
        :aria-label="__('Open drafts bulk action menu')"
        @click="openBulkActionMenu"
        @contextmenu.prevent="openBulkActionMenu"
      >
        <OzIcon name="bulk_action" :size="24" />
      </OzBaseButton>
      <SurfaceDraftBulkActionPopover
        v-if="xBulkActionMenu && bulkActionPopoverAnchor"
        :popover-anchor="bulkActionPopoverAnchor"
        @cancel="closeBulkActionMenu"
        @publish-all="publishAllDrafts"
        @delete-all="deleteAllDrafts"
      />
    </template>

    <template #minimized-content="{ modalData, dockModal, expandModal, restoreModalState }">
      <SurfaceComposerModalMinimized
        class="grow"
        :draft-cid="modalData.id"
        @dock="dockModal"
        @expand="expandModal"
        @restore-modal-state="restoreModalState"
      />
    </template>

    <template #main-content="{ isTransitioning, modalData, modalState, minimizeModal, dockModal, expandModal }">
      <SurfaceComposerModal
        class="grow"
        :draft-cid="modalData.id"
        :is-phone="isPhoneVariant"
        :is-docked="modalState === ModalState.Docked"
        :is-editor-animating="isTransitioning"
        @minimize="minimizeModal"
        @dock="dockModal"
        @expand="expandModal"
        @show-drafts-panel="xDraftsPanel = true"
      />
    </template>

    <SurfaceComposerDraftsPanel
      v-if="isContainerSmallerThanTabletPortrait && xDraftsPanel"
      @close="xDraftsPanel = false"
    />

    <!-- SurfaceEditAttachmentThumbnailModal is here instead of SurfacePanels so that global dialogs can render in front of it -->
    <SurfaceEditAttachmentThumbnailModal v-if="xEditThumbnailModal" />
  </DockableModalList>
</template>
