<script setup lang="ts">
/**
 * Renders a list of `DockableModal`s.
 * Automatically opens/closes modals when `modalDataArray` and `immutableModalData` props change.
 * Provides slots for the content of each modal.
 * Provides slot methods for minimizing, docking, and expanding each modal.
 * Automatically adapts to the size of the container (which by default is the viewport).
 */
import { toCssCustomProperties } from '@@/bits/css'
import { vSet } from '@@/bits/vue'
import DockableModal, {
  DOCKED_MODAL_WIDTH,
  EXPANDED_MODAL_WIDTH,
  MINIMIZED_MODAL_HEIGHT,
  MINIMIZED_MODAL_MAX_WIDTH,
  ModalState,
  WIDE_EXPANDED_MODAL_WIDTH,
} from '@@/vuecomponents/DockableModal.vue'
import { useWindowSize } from '@vueuse/core'
import { isEqual } from 'lodash-es'
import { computed, onBeforeUpdate, ref, useSlots, watch } from 'vue'

const BULK_ACTION_AREA_WIDTH = 40

interface ModalData {
  id: string
  data?: unknown
}

interface Modal extends ModalData {
  state: ModalState
  /** If `true`, the modal is marked for removal and will be removed after a transition (if any). */
  isMarkedForClosure?: boolean
  /** If `true`, the modal state cannot be changed. */
  isImmutable?: boolean
}

type ModalId = Modal['id']

const props = withDefaults(
  defineProps<{
    /**
     * Array of objects containing the data for the content of each modal.
     * Will be passed to the slots of each modal.
     *
     * Note: This prop assumes the modals are sorted from oldest to newest.
     * Visually, from inline start to inline end, the modals are oldest to newest.
     */
    modalDataArray: Array<ModalData & Partial<Pick<Modal, 'state'>>>
    /**
     * Data of an immutable modal.
     * There can be only one immutable modal at a time and its state cannot be changed.
     */
    immutableModalData?: ModalData | null
    /**
     * The initial state of modals when the component is mounted.
     * If when mounted, the component receives no modals, this prop does nothing and
     * modals added after that will be in `defaultNewModalState`.
     *
     * By default all modals are minimized to avoid distractions.
     *
     * If the specified state is `Docked` and `allowDockingMultipleModals` is `false`,
     * only dock the newest modal and minimize the rest. If `allowDockingMultipleModals`
     * is `true`, all modals will be docked.
     *
     * If the specified state is `Expanded`, only expand the newest modal.
     * @default ModalState.Minimized
     */
    initialModalState?: ModalState
    /**
     * The default state of a new modal when it's first opened.
     * @default ModalState.Docked
     */
    defaultNewModalState?: ModalState
    /**
     * Whether to allow multiple modals in the 'Docked' position at the same time.
     * @default false
     */
    allowDockingMultipleModals?: boolean
    /**
     * Whether the layout is for a phone.
     * @default false
     */
    isPhone?: boolean
    /**
     * Whether the modals are wider than usual.
     * Currently only applicable to expanded modals.
     * @default false
     */
    isWideModal?: boolean
    /**
     * Layout direction.
     * @default 'ltr'
     */
    dir?: 'ltr' | 'rtl'
    /**
     * The width of the container that the modals are rendered in.
     * If provided, make sure to add `position: relative` to the container element.
     * @default window.innerWidth
     */
    containerWidth?: number
    /**
     * The height of the container that the modals are rendered in.
     * If provided, make sure to add `position: relative` to the container element.
     * @default window.innerHeight
     */
    containerHeight?: number
    /**
     * The padding at the bottom of the container.
     * @default 0 (px)
     */
    bottomPadding?: number
    /**
     * The padding at the end of the container.
     * @default 132 (px)
     */
    endPadding?: number
    /**
     * The gap between modals when they are in the dock.
     * @default 16 (px)
     */
    gapBetweenModalsInDock?: number
  }>(),
  {
    immutableModalData: undefined,
    initialModalState: ModalState.Minimized,
    defaultNewModalState: ModalState.Docked,
    allowDockingMultipleModals: false,
    isPhone: false,
    isWideModal: false,
    dir: 'ltr',
    containerWidth: undefined,
    containerHeight: undefined,
    bottomPadding: 0,
    endPadding: 132,
    gapBetweenModalsInDock: 16,
  },
)

const emit = defineEmits<{
  (e: 'scrim-click'): void
  (e: 'scrim-esc'): void
}>()

// ----------
// MODAL DATA
// ----------

const modals = ref<Modal[]>([])
const isSyncingModalDataEnabled = ref(true)

const modalById = computed<Record<ModalId, Modal>>(() => {
  return modals.value.reduce((dataById, modal) => {
    dataById[modal.id] = modal
    return dataById
  }, {})
})

// ----------------
// STATE MANAGEMENT
// ----------------

const lastActiveState = ref<ModalState | null>(null)

const updateModal = (
  modal: Partial<Omit<Modal, 'data'>>, // Don't allow this method to update the data of the modal.
): boolean => {
  const modalIndex = modals.value.findIndex((m) => m.id === modal.id)
  const modalBeingUpdated = modals.value[modalIndex]
  // Can update the modal if it's mutable or if we want to close it.
  const canUpdateModal =
    modalBeingUpdated != null && (modalBeingUpdated.isImmutable !== true || modal.isMarkedForClosure === true)
  if (!canUpdateModal) return false
  vSet(modals.value, modalIndex, { ...modalBeingUpdated, ...modal })
  return true
}

const minimizeModal = (id: ModalId) => {
  updateModal({ id, state: ModalState.Minimized })
}

const minimizeOtherModals = (currentModalId: ModalId) => {
  // Compute the new modals state before setting it
  // to avoid multiple reactivity updates.
  const newModals: Modal[] = []
  for (const modal of modals.value) {
    if (modal.id === currentModalId) {
      newModals.push(modal)
    } else {
      newModals.push({ ...modal, state: ModalState.Minimized })
    }
  }
  if (!isEqual(newModals, modals.value)) modals.value = newModals
}

const minimizeAllModals = () => {
  // Compute the new modals state before setting it
  // to avoid multiple reactivity updates.
  const newModals: Modal[] = []
  for (const modal of modals.value) {
    newModals.push({ ...modal, state: ModalState.Minimized })
  }
  if (!isEqual(newModals, modals.value)) modals.value = newModals
}

const dockModal = (id: ModalId) => {
  if (updateModal({ id, state: ModalState.Docked })) {
    if (!props.allowDockingMultipleModals) minimizeOtherModals(id)
    lastActiveState.value = ModalState.Docked
  }
}

const expandModal = (id: ModalId) => {
  if (updateModal({ id, state: ModalState.Expanded })) {
    if (!props.allowDockingMultipleModals) minimizeOtherModals(id)
    lastActiveState.value = ModalState.Expanded
  }
}

const restoreModalState = (id: ModalId) => {
  const openingState = lastActiveState.value ?? props.defaultNewModalState
  // The modal is already minimized at this point, if we still
  // want to minimize it, this method would do nothing. Let's
  // dock it instead.
  if (openingState === ModalState.Minimized) {
    dockModal(id)
    return
  }
  if (updateModal({ id, state: openingState })) {
    if (!props.allowDockingMultipleModals) minimizeOtherModals(id)
  }
}

const closeModal = (id: ModalId) => {
  if (updateModal({ id, isMarkedForClosure: true })) {
    // Stop syncing `modals` with props until the leave transition ends.
    // (and `actuallyCloseModal` is called). This allows us to retain
    // the slot content of the modal for the leave transition duration.
    isSyncingModalDataEnabled.value = false
  }
}

const actuallyCloseModal = (id: ModalId) => {
  modals.value = modals.value.filter((m) => m.id !== id)
  // When multiple modals are closed at the same time, their transitions
  // may end at different times. We only resume syncing when there are no
  // modals left to close.
  if (modals.value.some((m) => m.isMarkedForClosure)) return
  isSyncingModalDataEnabled.value = true
  syncModalsWithProps()
}

// -------------
// PROPS SYNCING
// -------------

let isFirstSync = true

const getInitialStateForModal = (isNewestModal: boolean): ModalState => {
  switch (props.initialModalState) {
    case ModalState.Expanded:
      return isNewestModal ? ModalState.Expanded : ModalState.Minimized
    case ModalState.Docked:
      return props.allowDockingMultipleModals || isNewestModal ? ModalState.Docked : ModalState.Minimized
    default:
      return ModalState.Minimized
  }
}

const getStateForNewModal = (): ModalState => {
  return lastActiveState.value ?? props.defaultNewModalState
}

const findOrCreateModal = (
  newModal: Partial<Omit<Modal, 'isMarkedForClosure'>> & Pick<Modal, 'id'>,
  isNewestModal?: boolean,
): Modal => {
  const existingModal = modalById.value[newModal.id]
  if (existingModal != null) {
    // Always return modal with up-to-date data.
    existingModal.data = newModal.data
    // A sync will happen after we switch to phone layout (as we only show
    // 1 modal at a time on phone). We want to make sure this modal is in
    // expanded state.
    if (props.isPhone) existingModal.state = ModalState.Expanded
    return existingModal
  }

  if (isFirstSync) {
    return {
      ...newModal,
      state: getInitialStateForModal(isNewestModal === true),
    }
  }

  const state = newModal.state ?? getStateForNewModal()
  return {
    ...newModal,
    state,
  }
}

/**
 * There are 2 ways we can provide how the modals should be opened/closed:
 * 1. Via exposed methods. For example, `openNewModal` and `closeModal`.
 * 2. Via props. For example, add a new object to `modalDataArray` to open
 *    a new modal, or remove an object from `modalDataArray` to close a modal.
 *
 * The 1st approach is more imperative and is cleaner to implement. However,
 * in practice, the modals are often mapped to a list of data, and that list
 * can be modified in various places in the app. Trying to carry the exposed
 * methods from this component to all those places is a cumbersome task.
 *
 * Because of that, we use the 2nd approach. We take on more responsibility
 * to automatically open/close the correct modals when the props change
 * in exchange for convenience of usage.
 *
 * This function does all the magic and is the central piece of this component.
 */
const syncModalsWithProps = () => {
  if (!isSyncingModalDataEnabled.value) return

  const updatedModals: Modal[] = [...props.modalDataArray]
    .reverse() // Reverse the array because visually new modals are rendered inline end.
    .map((modalData, index) => {
      return findOrCreateModal(modalData, index === 0)
    })

  // Merge immutable modal (if exists) into the modals array.
  if (props.immutableModalData != null) {
    updatedModals.push(
      findOrCreateModal({
        ...props.immutableModalData,
        state: ModalState.Expanded, // Immutable modal is always expanded.
        isImmutable: true,
      }),
    )
  }

  // For each modal that is not in `updatedModals`, close it.
  const removedModals = modals.value.filter((m) => !updatedModals.some((md) => md.id === m.id))
  removedModals.forEach((m) => closeModal(m.id))

  // Check the flag again in case `closeModal` was called.
  if (!isSyncingModalDataEnabled.value) return

  // New modals can't (yet) be found in `modalById`. The first new modal found in `updatedModals`
  // is the newest because `updatedModals` are sorted from newest to oldest.
  const isNewModal = (m: Modal) => modalById.value[m.id] == null
  const newestModal = updatedModals.find(isNewModal)

  modals.value = updatedModals

  // If multiple docked modals are not allowed, and the newest modal is either docked or expanded,
  // minimize the rest.
  const shouldMinimizeOtherModals = newestModal?.state !== ModalState.Minimized && !props.allowDockingMultipleModals
  if (newestModal != null && shouldMinimizeOtherModals) {
    minimizeOtherModals(newestModal.id)
  }

  isFirstSync = false
}

// Use a watcher with a flag `isSyncingModalDataEnabled` to be able to control
// when `modals` is synced with props. See `closeModal` method for why.
watch([() => props.modalDataArray, () => props.immutableModalData], syncModalsWithProps, {
  immediate: true,
  deep: true,
})

// ----------
// APPEARANCE
// ----------

const slots = useSlots()
const xBulkAction = computed(() => !props.isPhone && modals.value.length > 1 && slots['bulk-action-content'])
const hasDockedModal = computed(() => modals.value.some((m) => m.state === ModalState.Docked))
const hasExpandedModal = computed(() => modals.value.some((m) => m.state === ModalState.Expanded))

const { width: windowWidth, height: windowHeight } = useWindowSize()
const computedContainerWidth = computed(() => props.containerWidth ?? windowWidth.value)
const computedContainerHeight = computed(() => props.containerHeight ?? windowHeight.value)

const bulkActionAreaSpace = computed(() =>
  xBulkAction.value ? BULK_ACTION_AREA_WIDTH + props.gapBetweenModalsInDock : 0,
)

const numOfDockedModals = computed(() => modals.value.filter((m) => m.state === ModalState.Docked).length)

/**
 * Custom CSS properties to use in the `<template>` & `<style>` blocks inside `DockableModal.vue`.
 */
const customCssProperties = computed(() => {
  const { dir, bottomPadding, gapBetweenModalsInDock, isWideModal } = props

  // Minimized modals can shrink. Their widths are divided equally after subtracting
  // the space taken by end padding, bulk action area & docked modals.
  const minimizedModalWidth = (() => {
    const availableSpaceForMinimizedModals =
      computedContainerWidth.value -
      props.endPadding -
      bulkActionAreaSpace.value -
      numOfDockedModals.value * (DOCKED_MODAL_WIDTH + props.gapBetweenModalsInDock)
    const shrunkMinimizedModalWidth =
      availableSpaceForMinimizedModals / (modals.value.length - numOfDockedModals.value) - props.gapBetweenModalsInDock
    return Math.min(shrunkMinimizedModalWidth, MINIMIZED_MODAL_MAX_WIDTH)
  })()

  return toCssCustomProperties(
    {
      containerWidth: computedContainerWidth.value,
      containerHeight: computedContainerHeight.value,
      bottomPadding,
      gapBetweenModalsInDock,
      minimizedModalWidth,
      MINIMIZED_MODAL_HEIGHT,
      DOCKED_MODAL_WIDTH,
      expandedModalWidth: isWideModal ? WIDE_EXPANDED_MODAL_WIDTH : EXPANDED_MODAL_WIDTH,
      scaleToMinimized: `scale(${minimizedModalWidth / DOCKED_MODAL_WIDTH})`,
      translateToCenter:
        dir === 'ltr'
          ? 'translate(calc(50% - var(--container-width) / 2), calc(50% - var(--container-height) / 2))'
          : 'translate(calc(var(--container-width) / 2 - 50%), calc(50% - var(--container-height) / 2))',
    },
    { numericUnit: 'px' },
  )
})

// --------------
// SCRIM HANDLING
// --------------

const mouseDownTarget = ref<EventTarget | null>(null)
const mouseUpTarget = ref<EventTarget | null>(null)

const mouseDown = (e: MouseEvent) => {
  mouseDownTarget.value = e.target
}

const mouseUp = (e: MouseEvent) => {
  mouseUpTarget.value = e.target
}

const scrimClick = (e: MouseEvent) => {
  // Emits if and only if the mouse down and mouse up events
  // are both on the same element and is outside of the overlay.
  // This ensures click and drag between overlay does not hide overlay.
  if (mouseDownTarget.value === mouseUpTarget.value && mouseDownTarget.value === e.target) {
    emit('scrim-click')
  }
}

const scrimEsc = () => {
  emit('scrim-esc')
}

// ----------------------
// EXPOSING MODAL METHODS
// ----------------------

type DockableModalInstance = InstanceType<typeof DockableModal>
const modalRefById = new Map<ModalId, DockableModalInstance>()

const assignModalRef = (id: ModalId, r: DockableModalInstance): void => {
  modalRefById.set(id, r)
}

const getElementRectInsideModal = (element: Element, id: ModalId): DOMRect | null => {
  return modalRefById.get(id)?.getElementRectInsideModal(element) ?? null
}

onBeforeUpdate(() => {
  // Clear the map before every update to avoid memory leaks.
  modalRefById.clear()
})

defineExpose({
  getElementRectInsideModal,
  minimizeModal,
  minimizeAllModals,
  dockModal,
  expandModal,
  restoreModalState,
  closeModal,
  hasDockedModal,
  hasExpandedModal,
})
</script>

<script lang="ts">
export { ModalState } from '@@/vuecomponents/DockableModal.vue'
</script>

<template>
  <div
    :class="[
      'inline-flex',
      'flex-row-reverse',
      'items-end',
      'box-border',
      'w-full',
      'h-full',
      hasExpandedModal && 'bg-common-ui-darkened-bg',
      !hasExpandedModal && 'pointer-events-none',
    ]"
    :style="{
      ...customCssProperties,
      paddingInlineEnd: `${endPadding}px`,
      paddingBottom: `${bottomPadding}px`,
    }"
    @mousedown="mouseDown"
    @mouseup="mouseUp"
    @click.self.stop.prevent="scrimClick"
    @keydown.esc.stop.prevent="scrimEsc"
  >
    <!-- BULK ACTION AREA -->
    <div
      v-if="xBulkAction"
      key="bulk-action-content"
      :class="[
        'flex-none',
        'z-minimized-modal',
        'w-10',
        hasExpandedModal ? 'pointer-events-none' : 'pointer-events-auto',
      ]"
      :style="{
        // We don't use `gap` in the parent element because it's not transitionable.
        marginInlineStart: `${props.gapBetweenModalsInDock}px`,
      }"
    >
      <slot name="bulk-action-content" />
    </div>

    <!-- MODALS -->
    <DockableModal
      v-for="modal in modals"
      :id="modal.id"
      :ref="(r) => assignModalRef(modal.id, r)"
      :key="modal.id"
      :data-id="modal.id"
      :state="modal.state"
      :is-marked-for-closure="modal.isMarkedForClosure || false"
      :dir="dir"
      :is-phone="isPhone"
      :is-wide="isWideModal"
      :modal-states="modals"
      :container-width="computedContainerWidth"
      :initial-end-offset="endPadding + bulkActionAreaSpace"
      :gap-between-modals-in-dock="gapBetweenModalsInDock"
      :class="hasExpandedModal && modal.state !== ModalState.Expanded ? 'pointer-events-none' : 'pointer-events-auto'"
      :style="{
        // We don't use `gap` in the parent element because it's not transitionable.
        marginInlineStart: `${props.gapBetweenModalsInDock}px`,
      }"
      @close="actuallyCloseModal(modal.id)"
    >
      <template #minimized-content>
        <slot
          v-if="modalById[modal.id]"
          name="minimized-content"
          :modal-data="modalById[modal.id]"
          :dock-modal="() => dockModal(modal.id)"
          :expand-modal="() => expandModal(modal.id)"
          :restore-modal-state="() => restoreModalState(modal.id)"
        />
      </template>
      <template #main-content="{ isTransitioning }">
        <slot
          v-if="modalById[modal.id]"
          name="main-content"
          :is-transitioning="isTransitioning"
          :modal-data="modalById[modal.id]"
          :modal-state="modal.state"
          :is-immutable="modal.isImmutable"
          :minimize-modal="() => minimizeModal(modal.id)"
          :dock-modal="() => dockModal(modal.id)"
          :expand-modal="() => expandModal(modal.id)"
        />
      </template>
    </DockableModal>

    <!-- DEFAULT SLOT -->
    <!-- Put it in an absolute div so it doesn't affect layout of the modals. -->
    <!-- Use this slot for modal-like components that should be children of `DockableModalList`. -->
    <div class="absolute">
      <slot></slot>
    </div>
  </div>
</template>
