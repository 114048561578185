<script setup lang="ts">
/**
 * A dockable modal is a modal that can be docked to the bottom end corner of the container.
 * On phone layout, the modal takes up the whole container.
 *
 * A dockable modal has 3 states:
 * - Minimized: shown as a small bar. The content of this state is provided by the `minimized-content` slot.
 * - Docked: expands up from the 'minimized' bar to show more content (provided by the `main-content` slot).
 * - Expanded: shown at the center of the container. Displays the same content as the 'docked' state.
 *
 * This component doesn't define the appearance of the modal. It only handles the positioning and transitions.
 *
 * IMPORTANT: This component is not meant to be used directly. Use `DockableModalList` instead.
 */
import { toCssCustomProperties } from '@@/bits/css'
import OzFocusTrap from '@@/library/v4/components/OzFocusTrap.vue'
import { usePreferredReducedMotion } from '@vueuse/core'
import { kebabCase, uniqueId } from 'lodash-es'
import { computed, nextTick, ref, watch } from 'vue'

const props = defineProps<{
  id: string
  state: ModalState
  isMarkedForClosure: boolean
  dir: 'ltr' | 'rtl'
  isPhone: boolean
  isWide: boolean
  modalStates: { id: string; state: ModalState }[]
  containerWidth: number
  initialEndOffset: number
  gapBetweenModalsInDock: number
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

/** End offset is the distance from the modal to the end edge of the available space. */
const endOffset = ref(props.initialEndOffset)

/**
 * Custom CSS properties to use in the `<template>` & `<style>` blocks.
 */
const customCssProperties = computed(() => {
  return toCssCustomProperties(
    {
      endOffset: props.dir === 'ltr' ? -endOffset.value : endOffset.value,
      translateToDock: 'translate(var(--end-offset), calc(var(--bottom-padding) * -1))',
    },
    { numericUnit: 'px' },
  )
})

const displayedState = computed(() => (props.isPhone ? ModalState.Expanded : props.state))

// ------------------------------------------------------------------------------
// MODAL TRANSITION
//
// We implement our own transition system to have more control over the lifecycle
// and how the classes are applied to the elements.
// ------------------------------------------------------------------------------

/**
 * Type function to exclude transition keys of the same states e.g. `Docked_To_Docked`.
 */
type ExcludeIdenticalStates<T extends string> = T extends `${infer A}_To_${infer B}` ? (A extends B ? never : T) : never

type StateTransitionKey = ExcludeIdenticalStates<`${ModalState}_To_${ModalState}`>
type ValidTransitionKey = StateTransitionKey | `${ModalState}Enter` | `${ModalState}Leave`

const rootEl = ref<Element>()

const transitionClasses = ref<string[]>([])
const isTransitionEnded = ref(false)
const transitionKey = ref<ValidTransitionKey>()

/**
 * Resolves once the DOM has been updated.
 */
const awaitDOMUpdates = async () => {
  await nextTick()
  await new Promise((resolve) => window.requestAnimationFrame(resolve))
}

/**
 * Resolves once all performed transitions have finished.
 */
const awaitTransitionEnd = async () => {
  await awaitDOMUpdates()
  // Steal from https://x.com/argyleink/status/1793679490222055709
  // Old Android browsers, like 6 and 7, don't support getAnimations
  await Promise.allSettled(
    typeof rootEl.value?.getAnimations === 'function'
      ? rootEl.value.getAnimations({ subtree: true }).map((animation) => animation.finished)
      : [],
  )
}

/**
 * Returns the space that a modal of the specified state would take in the dock.
 * Space = width + gap
 */
const getModalSpaceInDock = (state: ModalState, numOfDockedModals: number): number => {
  // Expanded modal doesn't take any space in dock.
  if (state === ModalState.Expanded) return 0
  // Docked modal takes a fixed space.
  if (state === ModalState.Docked) return DOCKED_MODAL_WIDTH + props.gapBetweenModalsInDock
  // Minimized modal space is divided equally after subtracting the space taken by docked modals.
  const availableSpaceForMinimizedModals =
    props.containerWidth -
    props.initialEndOffset -
    numOfDockedModals * (DOCKED_MODAL_WIDTH + props.gapBetweenModalsInDock)
  const minimizedModalSpace = availableSpaceForMinimizedModals / (props.modalStates.length - numOfDockedModals)
  return Math.min(minimizedModalSpace, MINIMIZED_MODAL_MAX_WIDTH + props.gapBetweenModalsInDock)
}

/**
 * Calculates the end offset of the current modal if it was in the specified state.
 * Used in `beforeTransition` hook to set the correct end offset for the transition.
 */
const updateEndOffset = (state: ModalState.Minimized | ModalState.Docked) => {
  // Create a new array of modal states with the current modal's state updated.
  const modalIndex = props.modalStates.findIndex((m) => m.id === props.id)
  const newModals = props.modalStates.map((m, i) => {
    if (i === modalIndex) return { ...m, state }
    return m
  })
  const numOfDockedModals = newModals.filter((m) => m.state === ModalState.Docked).length

  // Current modal's offset is the sum of all previous modals' offsets.
  let accumulatedOffset = props.initialEndOffset
  for (let i = 0; i < newModals.length; i++) {
    const previousModal = newModals[i - 1]
    const currentModal = newModals[i]
    const offset = previousModal ? getModalSpaceInDock(previousModal.state, numOfDockedModals) : 0
    accumulatedOffset += offset
    // Stop when we reach the current modal.
    if (currentModal.id === props.id) break
  }
  endOffset.value = accumulatedOffset
}

// -------------------------------------------------------------
// TRANSITION LIFECYCLE
//
//
//        {state}-enter-from     ->>     {state}-enter-to
//                      {state}-enter-active
//
//
// {state1}-to-{state2}-from     ->>     {state1}-to-{state2}-to
//                  {state1}-to-{state2}-active
//
//
//        {state}-leave-from     ->>     {state}-leave-to
//                      {state}-leave-active
//
//
// We use a similar naming convention for the transition classes
// compared to Vue. The watcher on `props.state` takes care of
// generating the class names based on the transition state and
// adding/removing them at appropriate timings.
// -------------------------------------------------------------

const beforeTransition = () => {
  isTransitionEnded.value = false
  // End offset is used in Minimized enter/leave, Docked enter/leave,
  // Minimized < -> Expanded & Docked < -> Expanded transitions.
  // We update it before those transitions start.
  const minimizeTransitions: ValidTransitionKey[] = [
    'MinimizedEnter',
    'MinimizedLeave',
    'Minimized_To_Expanded',
    'Expanded_To_Minimized',
  ]
  const dockTransitions: ValidTransitionKey[] = [
    'DockedEnter',
    'DockedLeave',
    'Docked_To_Expanded',
    'Expanded_To_Docked',
  ]
  if (minimizeTransitions.includes(transitionKey.value!)) {
    updateEndOffset(ModalState.Minimized)
  } else if (dockTransitions.includes(transitionKey.value!)) {
    updateEndOffset(ModalState.Docked)
  }
  transitionClasses.value = [kebabCase(`${transitionKey.value}-from`)]
}

const startTransition = () => {
  transitionClasses.value = [kebabCase(`${transitionKey.value}-active`), kebabCase(`${transitionKey.value}-to`)]
}

const afterTransition = () => {
  // Clean up the transition.
  transitionClasses.value = []
  isTransitionEnded.value = true

  // If this is a leave transition, emit a `close` event
  // so it can be removed properly.
  if (transitionKey.value?.endsWith('Leave')) emit('close')
}

const prefersReducedMotion = usePreferredReducedMotion()

watch(
  [displayedState, () => props.isMarkedForClosure],
  async ([newState, isClosing], [oldState]) => {
    const isEnterTransition = oldState == null
    const isLeaveTransition = isClosing === true

    if (isEnterTransition) {
      transitionKey.value = `${newState}Enter` as const
    } else if (isLeaveTransition) {
      transitionKey.value = `${oldState}Leave` as const
    } else {
      transitionKey.value = `${oldState}_To_${newState}` as StateTransitionKey
    }

    // If user prefers reduced motion, don't transition.
    if (prefersReducedMotion.value === 'reduce') {
      afterTransition()
      return
    }

    beforeTransition()
    await awaitDOMUpdates()
    startTransition()
    await awaitTransitionEnd()
    afterTransition()
  },
  { immediate: true },
)

const focusTrapDescriptionId = uniqueId('focusTrap')

/**
 * Show the minimized content when:
 * - The modal is minimized and is entering/leaving.
 * - The modal has completed transition from docked or expanded to minimized.
 */
const xMinimizedContent = computed(() => {
  if (displayedState.value !== ModalState.Minimized) return false
  return (
    transitionKey.value === 'MinimizedEnter' ||
    transitionKey.value === 'MinimizedLeave' ||
    (transitionKey.value?.endsWith('To_Minimized') && isTransitionEnded.value)
  )
})

// -----------------------------------------
// CALCULATING ELEMENT POSITION INSIDE MODAL
// -----------------------------------------

const modalContainer = ref<Element>()

/**
 * When the modal is expanded, we apply a `transform` to center it on the screen. `transform` creates
 * a new containing block that's not the viewport, so even `position: fixed` elements will be positioned
 * relative to it. See: https://darrenlester.com/blog/why-fixed-position-element-not-relative-to-viewport
 * To get around this, we expose this method that helps calculate the position of an element relative to
 * the modal container.
 */
const getElementRectInsideModal = (element: Element): DOMRect | null => {
  const containingBlockRect =
    props.state === ModalState.Expanded
      ? modalContainer.value?.getBoundingClientRect()
      : document.documentElement.getBoundingClientRect()
  if (containingBlockRect == null) return null
  const elementRect = element.getBoundingClientRect()
  return new DOMRect(
    elementRect.left - containingBlockRect.left,
    elementRect.top - containingBlockRect.top,
    elementRect.width,
    elementRect.height,
  )
}

defineExpose({
  getElementRectInsideModal,
})
</script>

<script lang="ts">
enum ModalState {
  Expanded = 'Expanded',
  Docked = 'Docked',
  Minimized = 'Minimized',
}

const MINIMIZED_MODAL_MAX_WIDTH = 164
const MINIMIZED_MODAL_HEIGHT = 40
const DOCKED_MODAL_WIDTH = 404
const EXPANDED_MODAL_WIDTH = 540
const WIDE_EXPANDED_MODAL_WIDTH = 828

export {
  DOCKED_MODAL_WIDTH,
  EXPANDED_MODAL_WIDTH,
  MINIMIZED_MODAL_HEIGHT,
  MINIMIZED_MODAL_MAX_WIDTH,
  ModalState,
  WIDE_EXPANDED_MODAL_WIDTH,
}
</script>

<template>
  <!-- WRAPPER -->
  <!-- The wrapper div acts like a spacing container for the modal in the inline flex layout. -->
  <!-- Its width will shrink down to 0 when the modal is expanded (to not take space in the dock). -->
  <div
    ref="rootEl"
    :data-dir="
      // We only want to read this value from CSS, not want it to affect the layout
      // -> Use `data-dir` instead of `dir`.
      dir
    "
    :class="[
      isPhone && 'is-phone',
      'wrapper',
      'flex-1',
      displayedState === ModalState.Minimized && ['min-w-0', 'max-w-41'],
      displayedState === ModalState.Docked && ['min-w-101', 'max-w-101'],
      displayedState === ModalState.Expanded && ['-me-[var(--gap-between-modals-in-dock)]', 'min-w-0', 'max-w-0'],
      'motion-reduce:!transition-none',
      transitionClasses,
    ]"
    :style="customCssProperties"
  >
    <!-- MODAL CONTAINER -->
    <!-- Defines the size of the modal. -->
    <div
      ref="modalContainer"
      :class="[
        'modal-container',
        'flex',
        displayedState === ModalState.Minimized && ['min-w-full', 'max-w-full', 'min-h-10', 'max-h-10'],
        displayedState === ModalState.Docked && [
          'min-w-full',
          'max-w-full',
          'min-h-0',
          'max-h-[calc(var(--container-height)-var(--bottom-padding)-64px)]',
        ],
        displayedState === ModalState.Expanded &&
          !isPhone && [
            'absolute',
            'bottom-0',
            'end-0',
            'translate-to-center',
            {
              'min-w-135 max-w-135': !isWide,
              'min-w-[828px] max-w-[828px]': isWide,
              'transition-[min-width,max-width] duration-200': isTransitionEnded,
            },
            'min-h-0',
            'max-h-[calc(var(--container-height)-128px)]',
          ],
        displayedState === ModalState.Expanded &&
          isPhone && [
            'absolute',
            'bottom-0',
            'inset-x-0',
            'min-w-[var(--container-width)]',
            'max-w-[var(--container-width)]',
            'min-h-[var(--container-height)]',
            'max-h-[var(--container-height)]',
            // This component should not know how it's being used, but I feel necessary to
            // leave this comment here for the context why we only transition the min/max
            // height on phone expanded layout.
            // We do it to support the post composer in submission request where we can
            // collapse it to see the surface header underneath.
            'transition-[min-height,max-height] duration-200',
          ],
        'motion-reduce:!transition-none',
      ]"
    >
      <!-- MINIMIZED CONTENT -->
      <slot v-if="xMinimizedContent" name="minimized-content" />
      <!-- MODAL CONTENT -->
      <!-- The actual content of the modal. -->
      <component
        :is="displayedState === ModalState.Expanded ? OzFocusTrap : 'div'"
        v-else
        class="grow flex min-w-0 modal-content"
        :aria-describedby="displayedState === ModalState.Expanded ? focusTrapDescriptionId : undefined"
      >
        <slot name="main-content" :is-transitioning="isTransitionEnded === false" />
        <p v-if="displayedState === ModalState.Expanded" :id="focusTrapDescriptionId" class="sr-only">
          {{ __('Press the Escape key to exit this dialog') }}
        </p>
      </component>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.translate-to-center {
  transform: var(--translate-to-center);
}

/* -------------------------------- */
/* Minimized Enter/Leave Transition */
/* -------------------------------- */

/* Wrapper */

.minimized-enter-from,
.minimized-leave-to {
  &.wrapper {
    /* Use !important because the parent component sets margin-inline-start inline. */
    margin-inline-start: 0 !important;
    @apply max-w-0 min-w-0;
  }
}

.minimized-enter-to,
.minimized-leave-from {
  &.wrapper {
    margin-inline-start: var(--gap-between-modals-in-dock) !important;
    max-width: var(--minimized-modal-width);
    min-width: var(--minimized-modal-width);
  }
}

.minimized-enter-active,
.minimized-leave-active {
  &.wrapper {
    @apply transition-[margin-inline-start,max-width,min-width] duration-200;
  }
}

/* Modal */

.minimized-enter-from,
.minimized-leave-to {
  & > .modal-container {
    max-width: var(--minimized-modal-width);
    min-width: var(--minimized-modal-width);
    transform: var(--translate-to-dock) scale(0);
  }
}

.minimized-enter-to,
.minimized-leave-from {
  & > .modal-container {
    max-width: var(--minimized-modal-width);
    min-width: var(--minimized-modal-width);
    transform: var(--translate-to-dock) scale(1);
  }
}

.minimized-enter-active,
.minimized-leave-active {
  & > .modal-container {
    @apply transition-transform duration-200;
  }
}

/* ----------------------------- */
/* Docked Enter/Leave Transition */
/* ----------------------------- */

/* Wrapper */

.docked-enter-from,
.docked-leave-to {
  &.wrapper {
    margin-inline-start: 0 !important;
    @apply max-w-0 min-w-0;
  }
}

.docked-enter-to,
.docked-leave-from {
  &.wrapper {
    margin-inline-start: var(--gap-between-modals-in-dock) !important;
    max-width: var(--docked-modal-width);
    min-width: var(--docked-modal-width);
  }
}

.docked-enter-active,
.docked-leave-active {
  &.wrapper {
    @apply transition-[margin-inline-start,max-width,min-width] duration-250;
  }
}

/* Modal */

.docked-enter-from,
.docked-leave-to {
  & > .modal-container {
    max-width: var(--docked-modal-width);
    min-width: var(--docked-modal-width);
    transform: var(--translate-to-dock) scale(0);
  }
}

.docked-enter-to,
.docked-leave-from {
  & > .modal-container {
    max-width: var(--docked-modal-width);
    min-width: var(--docked-modal-width);
    transform: var(--translate-to-dock) scale(1);
  }
}

.docked-enter-active,
.docked-leave-active {
  & > .modal-container {
    @apply transition-transform duration-250;
  }
}

/* ------------------------------- */
/* Expanded Enter/Leave Transition */
/* ------------------------------- */

/* Modal */

.expanded-enter-from,
.expanded-leave-to {
  /* --translate-to-center + 100% down (off-screen) */
  & > .modal-container {
    transform: translate(calc(50% - var(--container-width) / 2), 100%);
  }

  &[data-dir='rtl'] > .modal-container {
    transform: translate(calc(var(--container-width) / 2 - 50%), 100%);
  }

  &.is-phone > .modal-container,
  &.is-phone[data-dir='rtl'] > .modal-container {
    @apply translate-y-full;
  }
}

.expanded-enter-to,
.expanded-leave-from {
  & > .modal-container {
    transform: var(--translate-to-center);
  }

  &.is-phone > .modal-container,
  &.is-phone[data-dir='rtl'] > .modal-container {
    @apply translate-y-0;
  }
}

.expanded-enter-active,
.expanded-leave-active {
  & > .modal-container {
    @apply transition-transform duration-300;
  }
}

/* ------------------------------- */
/* Minimized <-> Docked Transition */
/* ------------------------------- */

/* Wrapper */

.minimized-to-docked-from,
.docked-to-minimized-to {
  &.wrapper {
    max-width: var(--minimized-modal-width);
    min-width: 0;
  }
}

.minimized-to-docked-to,
.docked-to-minimized-from {
  &.wrapper {
    max-width: var(--docked-modal-width);
    min-width: var(--docked-modal-width);
  }
}

.minimized-to-docked-active,
.docked-to-minimized-active {
  &.wrapper {
    @apply transition-[max-width,min-width] duration-250;
  }
}

/* Modal */

.minimized-to-docked-from,
.docked-to-minimized-to {
  & > .modal-container {
    max-width: var(--minimized-modal-width);
    max-height: var(--minimized-modal-height);
  }
}

.minimized-to-docked-to,
.docked-to-minimized-from {
  & > .modal-container {
    max-width: var(--docked-modal-width);
    max-height: calc(var(--container-height) - var(--bottom-padding) - 64px);
  }
}

.minimized-to-docked-active,
.docked-to-minimized-active {
  & > .modal-container {
    @apply transition-[max-width,max-height] duration-250;

    & > .modal-content {
      @apply overflow-hidden;
    }
  }
}

/* --------------------------------- */
/* Minimized <-> Expanded Transition */
/* --------------------------------- */

/* Wrapper */

.minimized-to-expanded-from,
.expanded-to-minimized-to {
  &.wrapper {
    max-width: var(--minimized-modal-width);
    min-width: var(--minimized-modal-width);
  }
}

.minimized-to-expanded-to,
.expanded-to-minimized-from {
  &.wrapper {
    @apply max-w-0 min-w-0;
  }
}

.minimized-to-expanded-active,
.expanded-to-minimized-active {
  &.wrapper {
    @apply transition-[max-width,min-width] duration-300;
  }
}

/* Modal */

.minimized-to-expanded-from,
.expanded-to-minimized-to {
  & > .modal-container {
    max-width: var(--minimized-modal-width);
    min-width: var(--minimized-modal-width);
    max-height: var(--minimized-modal-height);
    transform: var(--translate-to-dock);
  }
}

.minimized-to-expanded-to,
.expanded-to-minimized-from {
  & > .modal-container {
    max-width: var(--expanded-modal-width);
    min-width: var(--expanded-modal-width);
    max-height: calc(var(--container-height) - 128px);
    transform: var(--translate-to-center);
  }
}

.minimized-to-expanded-active,
.expanded-to-minimized-active {
  & > .modal-container {
    @apply transition-[max-width,min-width,max-height,transform] duration-300;

    & > .modal-content {
      @apply overflow-hidden;
    }
  }
}

/* ------------------------------ */
/* Docked <-> Expanded Transition */
/* ------------------------------ */

/* Wrapper */

.docked-to-expanded-from,
.expanded-to-docked-to {
  &.wrapper {
    max-width: var(--docked-modal-width);
    min-width: var(--docked-modal-width);
  }
}

.docked-to-expanded-to,
.expanded-to-docked-from {
  &.wrapper {
    @apply max-w-0 min-w-0;
  }
}

.docked-to-expanded-active,
.expanded-to-docked-active {
  &.wrapper {
    @apply transition-[max-width,min-width] duration-300;
  }
}

/* Modal */

.docked-to-expanded-from,
.expanded-to-docked-to {
  & > .modal-container {
    max-width: var(--docked-modal-width);
    min-width: var(--docked-modal-width);
    max-height: calc(var(--container-height) - var(--bottom-padding) - 64px);
    transform: var(--translate-to-dock);
  }
}

.docked-to-expanded-to,
.expanded-to-docked-from {
  & > .modal-container {
    max-width: var(--expanded-modal-width);
    min-width: var(--expanded-modal-width);
    max-height: calc(var(--container-height) - 128px);
    transform: var(--translate-to-center);
  }
}

.docked-to-expanded-active,
.expanded-to-docked-active {
  & > .modal-container {
    @apply transition-[max-width,min-width,max-height,transform] duration-300;
  }
}

/* ------ */
/* Common */
/* ------ */

/* Enter transitions use ease-out */
.minimized-enter-active,
.docked-enter-active,
.expanded-enter-active {
  &.wrapper,
  & > .modal-container {
    @apply ease-out;
  }
}

/* Leave transitions use ease-in */
.minimized-leave-active,
.docked-leave-active,
.expanded-leave-active {
  &.wrapper,
  & > .modal-container {
    @apply ease-in;
  }
}

/* Use absolute positioning to perform transition on modal to avoid being affected by wrapper's max/min-width */
.minimized-enter-from,
.minimized-enter-active,
.minimized-leave-from,
.minimized-leave-active,
.docked-enter-from,
.docked-enter-active,
.docked-leave-from,
.docked-leave-active,
.minimized-to-expanded-from,
.minimized-to-expanded-active,
.expanded-to-minimized-from,
.expanded-to-minimized-active,
.docked-to-expanded-from,
.docked-to-expanded-active,
.expanded-to-docked-from,
.expanded-to-docked-active {
  & > .modal-container {
    @apply absolute bottom-0 end-0;
  }
}

/* Change transform-origin based on layout direction */
.minimized-enter-from,
.minimized-enter-active,
.minimized-leave-from,
.minimized-leave-active,
.docked-enter-from,
.docked-enter-active,
.docked-leave-from,
.docked-leave-active,
.minimized-to-docked-from,
.minimized-to-docked-active,
.docked-to-minimized-from,
.docked-to-minimized-active {
  & > .modal-container {
    @apply origin-bottom-right;
  }

  &[data-dir='rtl'] > .modal-container {
    @apply origin-bottom-left;
  }
}
</style>
