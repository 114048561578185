// @file Composable to check for unsaved changes and prompt user to confirm before leaving the page.
import { showUnsavedChangesDialog } from '@@/bits/show_unsaved_changes_dialog'
import { onBeforeUnmount, onMounted } from 'vue'

export const useCheckUnsavedChangesBeforeReload = ({ shouldAlert }: { shouldAlert: () => boolean }): void => {
  const checkUnsavedChanges = (event: BeforeUnloadEvent): string | undefined => {
    if (shouldAlert()) {
      return showUnsavedChangesDialog(event)
    }

    return undefined
  }

  onMounted(() => {
    window.addEventListener('beforeunload', checkUnsavedChanges)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', checkUnsavedChanges)
  })
}
